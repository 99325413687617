<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
      <c-card title="개선 전 사진" class="cardClassDetailForm">
        <template slot="card-detail">
          <div class="col-12">
            <c-upload-picture
              height="300px"
              :attachInfo="attachBeforeInfo"
              :editable="editable&&!popupParam.disabled">
            </c-upload-picture>
          </div>
        </template>
      </c-card>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
      <c-card title="개선 후 사진" class="cardClassDetailForm">
        <template slot="card-detail">
          <div class="col-12">
            <c-upload-picture
              height="300px"
              isMultiTask
              :attachInfo="attachAfterInfo"
              :editable="editable&&!popupParam.disabled">
            </c-upload-picture>
          </div>
        </template>
      </c-card>
    </div>
  </div>
</template>

<script>
export default {
  name: 'checklistScenarioImprPicture',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        ramChecklistAssessScenarioId: '',  // 평가 일련번호
        sopImprovementIds: '',
        disabled: false,
      }),
    },
  },
  data() {
    return {
      attachBeforeInfo: {
        isSubmit: '',
        taskClassCd: 'CHECKLIST_SCENARIO_BEFORE',
        taskKey: '',
      },
      attachAfterInfo: {
        isSubmit: '',
        task: [],
      },
      editable: false,
    };
  },
  computed: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      // code setting
      this.$set(this.attachBeforeInfo, 'taskKey', this.popupParam.ramChecklistAssessScenarioId)
      if (this.popupParam.sopImprovementIds) {
        this.$set(this.attachAfterInfo, 'task', this.$_.map(this.$_.split(this.popupParam.sopImprovementIds, ','), item => {
          return {
            taskClassCd: 'IBM_AFTER',
            taskKey: item,
          }
        }))
      }
      // list setting
    },
  }
};
</script>
